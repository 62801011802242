import React, {Component} from 'react';


import Form from '../components/home/Form';
import Video from '../components/home/Video'
import AppStoreBadge from '../components/home/App-store-badge'

//import '../App.css';

class Home extends Component {
  constructor(){
    super()
    this.state = {
      videoURL : "testurl"
    }
  }
  render(){
    return (
      <div className="App">
        <div className="App-header">
            <Video/>
        </div>
        <div className="App-bottom">
        <div className="App-text">
              <div className="title">
                Find the island
              </div>
              <div className="subtitle">
                A worldwide game
              </div>
          </div>
          <div className="AppStore">
            <AppStoreBadge/>
          </div>
          <div className="App-subscribe">
            <Form/>
          </div>
        </div>
        {/*<div className="App-footer">
          @wearenada
    </div>*/}
      </div>
    );
  }
}

export default Home;
